.i {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 18px;
  border-radius: 50%;
  background-color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
}
.i-invert {
  background-color: black;
  color: white;
}

.hover {
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  @apply w-[254px] md:w-[348px] lg:w-[348px] xl:w-[418px] 2xl:w-[418px];
}

.hover .text {
  font-size: 15px;
  line-height: 20px;
}

.i-container:hover .hover {
  display: flex;
  cursor: pointer;
}

.offer-text-category-item-2,
.offer-text-category-item {
  font-weight: 400;
  font-size: 18px;
  font-family: var(--font-wixmadefordisplay);
  line-height: 22px;
  color: var(--dark-color);
  height: 52px;
}

.attribute-div-underline {
  border-bottom: 1px solid var(--light-gray-color);
}

.skulist-title-level-1 {
  font-size: 26px;
  font-weight: 500;
  font-family: var(--font-unbounded);
  line-height: 32px;
}

.skulist-text-level-1 {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
}

.skulist-title-level-2 {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
}
.skulist-title-level-2-2 {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
}

.text-button {
  font-family: var(--font-unbounded);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.text-total-amount-2,
.text-total-amount {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.text-total-cost-2,
.text-total-cost {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

.search-icon {
  @apply w-[44px];
  @apply h-[44px];
}

.search-link {
  font-size: 18px;
}
.search-link:hover {
  background-color: black;
  color: white;
}
.search-link:hover svg {
  fill: white;
  stroke: white;
}

.img-selected-border {
  border: 2px solid var(--primary-color);
}
.img-unselected-border {
  border: 2px solid var(--dark-color);
}

.img-selected-border-thin {
  border: 1px solid var(--primary-color);
}
.img-unselected-border-thin {
  border: 1px solid var(--dark-color);
}

@media (max-width: 1919px) {
  .skulist-title-level-1 {
    font-size: 26px;
    line-height: 32px;
  }
  .skulist-text-level-1 {
    font-size: 20px;
    line-height: 25px;
  }
  .skulist-title-level-2 {
    font-size: 13px;
    line-height: 16px;
  }
  .text-button {
    font-size: 16px;
    line-height: 20px;
  }
  .text-total-amount-2,
  .text-total-amount {
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
  }

  .text-total-cost-2,
  .text-total-cost {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
  }
  .search-link {
    font-size: 15px;
  }
  .hover .text {
    font-size: 13px;
    line-height: 16px;
  }
}
@media (max-width: 1365px) {
  .skulist-title-level-1 {
    font-size: 24px;
    line-height: 30px;
  }
  .skulist-text-level-1 {
    font-size: 13px;
    line-height: 17px;
  }
  .skulist-title-level-2 {
    font-size: 13px;
    line-height: 17px;
  }
  .text-button {
    font-size: 13px;
    line-height: 20px;
  }
  .text-total-amount-2,
  .text-total-amount {
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
  }
  .text-total-cost-2,
  .text-total-cost {
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
  }
  .offer-text-category-item-2,
  .offer-text-category-item {
    font-size: 12px;
    line-height: 15px;
  }
  .search-link {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .skulist-title-level-1 {
    font-size: 24px;
    line-height: 30px;
  }
  .skulist-text-level-1 {
    font-size: 13px;
    line-height: 17px;
  }
  .skulist-title-level-2 {
    font-size: 13px;
    line-height: 17px;
  }
  .text-button {
    font-size: 13px;
    line-height: 20px;
  }
  .text-total-amount-2,
  .text-total-amount {
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
  }
  .search-link {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .skulist-title-level-1 {
    font-size: 18px;
    line-height: 23px;
  }
  .skulist-text-level-1 {
    font-size: 12px;
    line-height: 15px;
  }
  .skulist-title-level-2 {
    font-size: 12px;
    line-height: 15px;
  }
  .text-button {
    font-size: 12px;
    line-height: 17px;
  }
  .text-total-amount-2,
  .text-total-amount {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
  }
  .text-total-cost-2,
  .text-total-cost {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }
  .offer-text-category-item-2,
  .offer-text-category-item {
    font-size: 12px;
    line-height: 15px;
  }
  .search-link {
    font-size: 12px;
  }
  .hover .text {
    font-size: 12px;
    line-height: 15px;
  }
}
